<template>
  <v-dialog
      :value="dialog"
      max-width="500"
      @input="toggleDialog"
  >
    <v-card>
      <v-card-title class="text-h5">
        <div
            v-html="confirmData.title"
        >
        </div>
      </v-card-title>
      <v-card-text>
        <div
            v-html="confirmData.content"
        >
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color=""
            text
            :loading="btnLoading"
            @click="confirmLeftBtnAction(confirmData.data)"
        >
          {{ confirmData.leftBtn }}
        </v-btn>
        <v-btn
            color=""
            text
            @click="confirmRightBtnAction(confirmData.data)"
        >
          {{ confirmData.rightBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data: () => ({
    isDisabled: false
  }),
  props: {
    dialog: {
      type: Boolean
    },
    confirmData: {
      type: Object,
      // default: function () {
      //   return {}
      // }
    },
    btnLoading: {
      type:Boolean,
      default: false
    }
  },
  methods: {
    toggleDialog: function () {
      this.$emit("update:dialog", !this.dialog)
    },

    confirmLeftBtnAction: function (data) {

      if (this.isDisabled) {
        return false
      } else {
        this.isDisabled = true
      }

      this.$emit("confirmLeftBtnAction", data)
    },
    confirmRightBtnAction: function (data) {
      this.$emit("confirmRightBtnAction", data)
    },
  },
  watch: {
    dialog() {
      console.log("dialog check : " + this.dialog);
      if (!this.dialog) {
        this.isDisabled = false
      }
    }
  }


}
</script>

<style scoped>

</style>