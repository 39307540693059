<template>
  <div>
    <v-navigation-drawer v-model="drawer" app v-if="$vuetify.breakpoint.smAndDown" class="overflow-y-auto">
      <v-list
          nav
      >
        <v-list-item-group
            active-class="selectedMenu"
        >
          <v-list-item
              v-for="item in menuItems"
              :key="item.title"
              :to="item.path"
              link
              style="box-shadow: 1px 0px 2px rgba(33,33,33,0.6);"
          >
            <v-list-item-title
                style="font-size:13px;"
            >{{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <!--        <v-list-tile-->
        <!--            v-for="item in menuItems"-->
        <!--            :key="item.title"-->
        <!--            :to="item.path">-->

        <!--          <v-list-tile-content>{{ item.title }}</v-list-tile-content>-->
        <!--        </v-list-tile>-->
      </v-list>
    </v-navigation-drawer>
    <div v-if="$vuetify.breakpoint.mdAndDown" class="fx" data-v-1fa066c4="">
      <ul class="fxhead" data-v-1fa066c4="" style="padding-left:0px">
        <li class="atten" data-v-1fa066c4=""><a data-v-1fa066c4="" @click="$move('/deposit')"><em data-v-1fa066c4=""></em><span data-v-1fa066c4="">입금</span></a>
        </li>
        <li class="notice" data-v-1fa066c4=""><a data-v-1fa066c4="" @click="$move('/withdrawal')"><em data-v-1fa066c4=""></em><span
            data-v-1fa066c4="">출금</span></a></li>
        <li class="home" data-v-1fa066c4=""><a data-v-1fa066c4="" @click="$move('/mybet')"><em data-v-1fa066c4=""></em><span data-v-1fa066c4="">베팅내역</span></a>
        </li>
        <li class="ques" data-v-1fa066c4=""><a data-v-1fa066c4="" @click="$move('/customer')"><em data-v-1fa066c4=""></em><span data-v-1fa066c4="">고객센터</span></a>
        </li>
        <li class="my" data-v-1fa066c4=""><a data-v-1fa066c4="" @click="$move('/myPage')"><em data-v-1fa066c4=""></em><span data-v-1fa066c4="">마이페이지</span></a>
        </li>
      </ul>
    </div>
    <v-app-bar
        absolute
        app
        dark
        id="appBar"
        elevation="0"
        class="justify-center"
        :style="$vuetify.breakpoint.lgAndUp? 'min-width:1314px' : 'min-width:320px'"
        :height="$vuetify.breakpoint.lgAndUp ? ($route.path.includes('partner/') ? '90':'131') : '48'"
    >

      <div
          class="pa-0"
      >

        <div class=""
             :class="[$vuetify.breakpoint.lgAndUp? 'd-flex align-center justify-center':'', $route.path.includes('partner/') ? 'agencyBarBg':'']"
        >

          <div class="">
            <div class="d-flex align-center">
              <span class="hidden-md-and-up">
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
              </span>

              <user-pc
                  v-if="$vuetify.breakpoint.lgAndUp"
                  :nowTime="nowTime"
                  :balance="balance"
                  :unreadMessage="unreadMessage"
                  :nickname="nickname"
                  :gradeValue="gradeValue"
                  :menuItems="menuItems"
                  :isLogin="isLogin"
                  :role="role"
                  :rateFlag.sync="rateFlag"
                  :hasChildren="hasChildren"
                  v-on:openJoinDialog="openJoinDialog"
                  v-on:openLoginDialog="openLoginDialog"
                  v-on:openRecommenderDialog="openRecommenderDialog"
                  v-on:logout="logout"
                  v-on:goLink="goLink"
                  v-on:checkMenuTab="checkMenuTab"
                  v-on:autoAsk="autoAsk"
                  v-on:launchGame="launchGame"
                  v-on:getGameList="getGameList"
              ></user-pc>
              <user-mobile
                  v-else
                  :nowTime="nowTime"
                  :balance="balance"
                  :unreadMessage="unreadMessage"
                  :nickname="nickname"
                  :gradeValue="gradeValue"
                  :drawerRight.sync="drawerRight"
                  :isLogin="isLogin"
                  :role="role"
                  :rateFlag="rateFlag"
                  :hasChildren="hasChildren"
                  v-on:openLoginDialog="openLoginDialog"
                  v-on:openJoinDialog="openJoinDialog"
                  v-on:openRecommenderDialog="openRecommenderDialog"
                  v-on:toggleDrawerRight="toggleDrawerRight"
                  v-on:logout="logout"
                  v-on:goLink="goLink"
                  v-on:checkMenuTab="checkMenuTab"
                  v-on:autoAsk="autoAsk"
                  v-on:launchGame="launchGame"
                  v-on:getGameList="getGameList"
              >
              </user-mobile>
            </div>

            <!--          <div class="align-center" style="min-width:200px !important;" v-if="$vuetify.breakpoint.mdAndDown">-->
            <!--            <v-img-->
            <!--                class="shrink hidden-lg-and-up mx-auto"-->
            <!--                contain-->
            <!--                src="@/assets/img/logo.png"-->
            <!--                transition="scale-transition"-->
            <!--                :width="$vuetify.breakpoint.lgAndUp ? 150: 150"-->
            <!--                :height="$vuetify.breakpoint.lgAndUp ? 160: 48"-->
            <!--                @click="goLink('main')"-->
            <!--                style=""-->
            <!--            />-->
            <!--          </div>-->


          </div>
        </div>

      </div>

    </v-app-bar>

    <confirm-dialog
        :dialog.sync="dialog.confirm"
        :confirmData="dialog.confirmData"
        v-on:confirmLeftBtnAction="confirmLeftBtnAction"
        v-on:confirmRightBtnAction="confirmRightBtnAction">
    </confirm-dialog>
    <recommender-dialog
        v-if="dialog.recommender"
        :dialog.sync="dialog.recommender"
        v-on:recommenderAlertAction="recommenderAlertAction"
        v-on:checkRecommender="checkRecommender"
    ></recommender-dialog>
    <login-dialog
        v-if="dialog.login"
        :loginDialog.sync="dialog.login"
        v-on:login="login"
        v-on:openJoinDialog="openJoinDialog"
        ref="loginDialog"
    />
    <join-dialog
        v-if="dialog.join"
        :joinDialog.sync="dialog.join"
    />
    <game-dialog
        v-if="dialog.game"
        :dialog.sync="dialog.game"
        :gameData="dialog.gameData"
        v-on:launchGame="launchGame"
    >
    </game-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import userPc from "@/components/_Default/User/Pc.vue"
import userMobile from "@/components/_Default/User/Mobile.vue"
import sideLeague from "@/components/_Default/sideLeagues.vue"
import RecommenderDialog from '@/components/Member/RecommenderDialog.vue'
import LoginDialog from '@/components/Member/LoginDialog.vue'
import JoinDialog from '@/components/Member/JoinDialog.vue'
import {eventBus} from '@/plugins/eventBus';
import GameDialog from "@/components/Game/Casino/GameDialog";
import Stomp from "webstomp-client";
import SockJS from "sockjs-client";

export default {
  name: "DefaultTopBar",
  data: () => ({
    role: localStorage.role,
    rateFlag: localStorage.rateFlag,
    interval: null,
    balanceInterval: null,
    drawer: false,
    drawerRight: false,
    nowTime: 0,
    balance: {},
    unreadMessage: 0,
    nickname: '',
    gradeValue: '',
    hasChildren: 'true',
    menuItems: [
      {title: '라이브카지노', path: '/casino/live'},
      {title: '호텔카지노', path: '/casino/hotel'},
      {title: '슬롯', path: '/casino/slot'},
      {title: '베팅내역', path: '/mybet/SPORTS_GAME'},
      {title: '공지사항', path: '/notice/NOTICE'},
      {title: '이벤트', path: '/notice/EVENT'},
      {title: '고객센터', path: '/customer'},
      {title: '충전', path: '/deposit'},
      {title: '환전', path: '/withdrawal'},
      {title: '포인트', path: '/point'},
    ],

    AgencyMenuItems: [

      {
        icon: 'mdi-face-agent',
        title: '회원관리',
        path: '/member',
        items: [
          {title: '회원리스트', path: '/member'},
          {title: '파트너리스트', path: '/myAgency'},
          /*{title: '현재접속자', path: '/autoAnswer'}*/
        ]
      },
      {
        icon: 'mdi-currency-krw',
        title: '충환전관리',
        path: '/Customer',
        items: [
          {title: '충환전내역', path: '/Agency/Exchange'}
        ]
      },

      {
        icon: 'mdi-trophy',
        title: '베팅',
        path: '/',
        items: [
          {title: '베팅내역', path: '/betHistory'}
        ]
      },

      {
        icon: 'mdi-calculator',
        title: '정산',
        path: '/',
        items: [
          /*{title: '대시보드', path: '/dashboard'},*/
          {title: '내정산', path: '/myCalculation'},
          {title: '하위정산', path: '/otherCalculation'},
          /*{title: '정산내역', path: '/betHistory/MINI_GAME'},
          {title: '정산요청', path: '/betHistory/MINI_GAME'}*/
        ]
      },
    ],
    dialog: {
      login: false,
      recommender: false,
      alert: false,
      alertOption: {
        persistent: true
      },
      alertText: '',
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
      join: false,
      joinData: {
        recommender: ''
      },
      game: false,
      gameData: {}
    },
    menuTab: null,
    currPath: '',
    connected: false,
    isLogin: false,
    subscriptionId: '',
    socketInterval: null,
    socket:null,
    stompClient:null,
    loginBtnLoading: false,
  }),
  components: {
    userPc,
    userMobile,
    sideLeague,
    RecommenderDialog,
    LoginDialog,
    JoinDialog,
    GameDialog
  },
  methods: {
    openJoinDialog: function () {
      this.dialog.join = true;
      this.dialog.login = false;
    },
    openLoginDialog: function () {
      this.dialog.login = true;
      this.dialog.join = false;
    },

    getGameList: async function (item) {
      this.loading = true
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .get(`/app/casino/swix/info/vendors/${item.code}/games`,
              {
                params: {},
              })
          .then(response => {

            if (response.status === 200) {
              isSuccess.flag = true;
              this.gameList = response.data.list;
              for (let i = 0; i < this.gameList.length; i++) {
                this.gameList[i].thirdPartyCode = item.code
              }
              console.log('getGameList', this.gameList)
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "게임을 불러오기 오류.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })

      if (isSuccess.flag) {
        if (this.gameList.length > 0) {
          this.dialog.game = true;
          this.dialog.gameData.gameList = this.gameList
          this.dialog.gameData.title = item.name
        } else {
          this.dialog.alertText = '게임이 존재하지 않습니다.';
          this.dialog.alert = true;
        }
        // this.$refs.casinoList.openDetail()
      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.getGameList(code)
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            }
            this.dialog.alert = true;
            this.dialog.alertText = refreshTokenResult.msg
          }


        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '게임 불러오기 오류.';
          this.dialog.alert = true;
        }
      }

      this.loading = false;

    },

    launchGame: function (item) {
      this.$launchGame(item)
    },

    login: async function (id, pw) {
      const username = id;
      const password = pw;

      if (!username || !password) {
        return false;
      }
      this.$refs.loginDialog.changeLoginBtnLoading(true)
      this.loginBtnLoading = true
      await this.$store
          .dispatch("LOGIN", {username, password})
          .then(async () => {
            await this.$updateMyInfo()
            this.$move("/")
            //this.$router.push({name: "main"});
          })
          .catch(ex => {
            console.log(ex)
            // console.log(ex.response.status)
            this.dialog.alertText = ex.response.data.message;
            this.dialog.alert = true;
            console.log(this.dialog)
            this.showAlert()
          });
      this.$refs.loginDialog.changeLoginBtnLoading(false)
    },
    recommenderAlertAction: function () {
      this.dialog.recommender = !this.dialog.recommender
    },
    openRecommenderDialog: function () {
      this.dialog.recommender = true
    },
    goRegist: function (recommender) {
      this.dialog.recommender = false
      this.dialog.join = true
      this.dialog.joinData.recommender = recommender
    },
    checkRecommender: async function (recommender) {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};

      await axios
          .post('/app/member/check/recommender',
              {code: recommender},)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "잘못된 추천인 코드입니다.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })


      if (isSuccess.flag) {
        this.goRegist(recommender)
      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.checkRecommender(recommender)
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
              this.dialog.alertText = refreshTokenResult.msg
                  } else {
                    this.dialog.alertText = refreshTokenResult.msg
            }
            this.dialog.alert = true;

          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '잘못된 추천인 코드입니다.';
          this.dialog.alert = true;
        }
      }
      this.loading = false;
    },
    toggleDrawerRight: function () {
      this.drawerRight = !this.drawerRight
    },
    setMenuTab: function () {
      this.currPath = this.$route.path
      const tabIndex = this.menuItems.findIndex(tab => tab.path === this.$route.path)
      this.menuTab = tabIndex

    },
    checkMenuTab: async function (targetPath) {
      console.log(targetPath, this.currPath)
      if (targetPath === this.currPath) {
        await eventBus.getSiteInfo()
        eventBus.getData()
      } else {
        this.$router.push({path: targetPath})
      }
    },
    goLink: function (target) {
      this.$goLink(target)
    },
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    logout: function () {
      this.dialog.confirmData.leftBtn = '확인'
      this.dialog.confirmData.rightBtn = '취소'
      this.dialog.confirmData.title = '로그아웃'
      this.dialog.confirmData.content = '로그아웃 하시겠습니까?'
      this.dialog.confirm = true
    },
    confirmRightBtnAction: function () {
      this.dialog.confirmData.leftBtn = ''
      this.dialog.confirmData.rightBtn = ''
      this.dialog.confirmData.title = ''
      this.dialog.confirmData.content = ''
      this.dialog.confirm = false
    },
    confirmLeftBtnAction: async function () {
      //로그아웃 api 추가
      // axios
      //     .post("/app/auth/signOut", {})
      //     .then(response => {
      //       console.log('refresh !!! STORE', response)
      //
      //       // 성공적으로 로그인이 되었을 경우
      //       if (response.status === 200) {
      //         console.log('signout success')
      //       } else {
      //         console.log('signout fails')
      //       }
      //
      //     }).catch(async ex => {
      //   console.log("signout fail", ex)
      // });

      if (this.$logout().flag) {
        this.confirmRightBtnAction()
        this.$move("/")
      } else {
        this.confirmRightBtnAction()
        this.dialog.alert = true;
        this.dialog.alertText = '로그아웃을 실패하였습니다. 관리자에게 문의해주세요.'
      }
    },

    setDate() {
      const curr = new Date();
      const utc =
          curr.getTime() +
          (curr.getTimezoneOffset() * 60 * 1000);
      const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
      const kr_curr = new Date(utc + (KR_TIME_DIFF));
      let weekday = ['일', '월', '화', '수', '목', '금', '토']
      let year = kr_curr.getFullYear();
      let month = kr_curr.getMonth() + 1 < 10 ? "0" + (kr_curr.getMonth() + 1) : kr_curr.getMonth() + 1;
      let date = kr_curr.getDate() < 10 ? "0" + kr_curr.getDate() : kr_curr.getDate();
      let week = weekday[kr_curr.getDay()];
      let hh = kr_curr.getHours() < 10 ? "0" + kr_curr.getHours() : kr_curr.getHours();
      let mm = kr_curr.getMinutes() < 10 ? "0" + kr_curr.getMinutes() : kr_curr.getMinutes();
      let ss = kr_curr.getSeconds() < 10 ? "0" + kr_curr.getSeconds() : kr_curr.getSeconds();
      return {
        'year': year
        , 'month': month
        , 'date': date
        , 'week': week
        , 'hh': hh
        , 'mm': mm
        , 'ss': ss
      }
    },
    nowTimes() {
      this.nowTime =
          // this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + "-" +
          this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + " (" + this.setDate().week + ") " + "<br />" + this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      // this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      eventBus.getNowTime(this.nowTime.replace('<br />', ''))
    },
    getBalance: function () {
      axios
          .get('/app/member/balance',
              {
                params: {},
              })
          .then(response => {
            this.balance = response.data
          })
          .catch(async ex => {
            console.log(ex)
            if (ex.response.status === 401) {
              console.log('getBalance end')
              clearInterval(this.balanceInterval)
              this.balanceInterval = null
              //
              // let refreshTokenResult = await this.$refreshToken()
              // console.log(refreshTokenResult)
              // if (refreshTokenResult.flag) {
              //   await this.getBalance()
              // } else {
              //   if (refreshTokenResult.status === 403) {
              //     this.dialog.alertActionType = 'logout'
              //     this.dialog.alertOption.persistent = true
              //     this.dialog.alertText = this.$error403msg
              //   } else {
              //     this.dialog.alertText = refreshTokenResult.msg
              //   }
              //   this.dialog.alert = true;
              // }
            } else if (ex.response.status === 403) {
              this.clearInterval()
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })

    },
    getUnreadMessage: async function () {
      await axios
          .get('/app/messages/unread',
              {
                params: {},
              })
          .then(response => {
            // console.log(response.data)
            this.unreadMessage = response.data
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getUnreadMessage()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                  this.dialog.alertText = refreshTokenResult.msg
                } else {
                  this.dialog.alertText = refreshTokenResult.msg
                }
                this.dialog.alert = true;


              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }
          })
      if (this.unreadMessage > 0 && !this.$route.path.includes('/message')) {
        this.checkUnreadMessage();
      }
    },
    checkUnreadMessage: function () {
      this.dialog.alertText = '읽지 않은 매세지가 있습니다. 확인하시겠습니까?';
      this.dialog.alert = true;
      this.dialog.alertActionType = 'goMessage'
    },


    getData: function () {

      this.getBalance()
      this.getUnreadMessage()
      this.setMenuTab()
    },
    memberStatusCheck: function () {
      // console.log(localStorage.memberId)
      // console.log(localStorage.username)
      // console.log(localStorage.nickname)
      // console.log(localStorage.grade)
      // console.log(localStorage.gradeValue)
      // console.log(localStorage.status)
      // console.log(localStorage.statusValue)
      // console.log(localStorage)
      const status = localStorage.status;
      // console.log('memberStatusCheck',localStorage)
      if (status === 'AUTHORIZED') {

      } else {
        this.dialog.alertText = '접속 불가한 계정입니다. 관리자에게 문의하시길 바랍니다.';
        this.dialog.alert = true;
        this.dialog.alertActionType = 'doNotAccess'
        // if (localStorage.status === 'UNAUTHORIZED') {
        //
        // } else if (localStorage.status === 'PAUSE') {
        //
        // } else if (localStorage.status === 'WITHDRAW') {
        //
        // }
      }
    },

    showAlarm: function (item) {
      this.dialog.alert = true;
      this.dialog.alertText = item.message;
      this.dialog.alertActionType = '';
    },

    autoAsk: async function () {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .post('/app/store/charge/autoAsk',
              {
                headers: {"Content-Type": `application/json`}
              })
          .then(response => {
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
              isSuccess.msg = response.data.message;
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "1:1 문의를 실패하였습니다.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })

      if (isSuccess.flag) {
        this.dialog.alertText = isSuccess.msg;
        this.dialog.alert = true;
        this.dialog.alertActionType = 'goCustomer';
        // this.dialog.write = false;
        // this.dialog.snackBar = true;
        // this.dialog.snackBarText = isSuccess.msg;
      } else {

        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.autoAsk()
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
              this.dialog.alertText = refreshTokenResult.msg
                  } else {
                    this.dialog.alertText = refreshTokenResult.msg
            }
            this.dialog.alert = true;

          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '1:1 문의를 실패하였습니다.';
          this.dialog.alert = true;
        }
      }
      this.loading = false;
    },
    subScribeInplay: function () {
      this.stompClient.subscribe(`/queue/${localStorage.memberId}/alert`, async tick => {
        console.log('topbar!')
        this.subscriptionId = tick.headers.subscription
        this.showAlarm(JSON.parse(tick.body))
      });
    },
    connect() {
      if (localStorage.memberId !== undefined && localStorage.memberId !== '')
      // this.$initSocket()
      this.socket = new SockJS(process.env.VUE_APP_WS_URL);
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect(
          {},
          frame => {
            this.connected = true;
            this.subScribeInplay()

          },
          error => {
            console.log(error);
            this.connected = false;
          }
      );
    },
    disconnect() {
      this.stompClient.unsubscribe(this.subscriptionId)
      this.stompClient.disconnect()
      this.$socketDisconnect()
      this.connected = false;
      clearInterval(this.socketInterval)
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },
    checkIsLogin: async function () {
      if (localStorage.accessToken !== undefined) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },
    clearInterval: function () {
      clearInterval(this.interval)
      this.interval = null
      clearInterval(this.balanceInterval)
      this.balanceInterval = null
      clearInterval(this.socketInterval)
      this.socketInterval = null

    },
    startBalanceInterval: async function () {
      console.log('getBalance restart')
      for (let i = 0; i < 3; i++) {
        if (this.balanceInterval === null) {
          console.log('getBalance restart success')
          this.balanceInterval = setInterval(this.getBalance.bind(this), 5000);
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    },
    socketCheck: function () {
      if(!this.stompClient.connected) {
        this.connect();
      }
    },
  },
  mounted() {

    // if (localStorage.accessToken !== undefined) {
    //   this.getData();
    //   this.connect();
    // }
    this.checkIsLogin()
    // eventBus.getSiteInfo()
  },
  created() {
    this.$eventBusInitAlert()
    eventBus.$off('updateTopBarData')
    eventBus.$on('updateTopBarData', () => {
      if (localStorage.accessToken !== undefined) {
        this.getData()
      }
    })
    eventBus.$off('clearInterval')
    eventBus.$on('clearInterval', () => {
      this.clearInterval()
    })
    eventBus.$off('startBalanceInterval')
    eventBus.$on('startBalanceInterval', () => {
      this.startBalanceInterval()
    })
    console.log(this.balanceInterval)
    this.nowTimes();
    this.setDate()
    if(this.connected !== true){
      this.connect();
    }
    setInterval(this.nowTimes.bind(this), 1000);
    //회원 상태 체크
    if (localStorage.accessToken !== undefined) {
      this.interval = setInterval(this.memberStatusCheck.bind(this), 3000);
      this.balanceInterval = setInterval(this.getBalance.bind(this), 5000);
      this.socketInterval = setInterval(this.socketCheck.bind(this), 3000);
    }

    //회원정보 불러오기
    //setInterval(this.getMember.bind(this), 5000);
  },

  beforeDestroy() {
    this.disconnect()
    this.clearInterval()
    this.nickname = ''
    this.gradeValue = ''
    this.hasChildren = 'true'
  },

  watch: {
    $route(to, from) {
      // console.log(to,from)
      //this.getData()
      this.checkIsLogin()
      if(this.connected !== true){
        this.connect();
      }
      this.currPath = to.path
      if (this.nickname === '' && localStorage.nickname !== '') {
        this.nickname = localStorage.nickname
        this.gradeValue = localStorage.gradeValue
        this.hasChildren = localStorage.hasChildren
        this.rateFlag = localStorage.rateFlag
        this.role = localStorage.role
      }
      if (localStorage.accessToken === undefined) {
        this.clearInterval()
      }
      // console.log('currpath!!@!',this.currPath)
    },
    dialog: {
      handler: function (val, oldVal) {
        console.log('alert!!!')
        if (this.dialog.alert) {

          this.showAlert()
        }
      },
      deep: true

    }
  }
}
</script>

<style scoped>
/*ul {*/
/*  list-style: none;*/
/*}*/
/*ul li {*/
/*  float:left;*/
/*  font-size: 24px;*/
/*}*/
/*.menuTag {*/
/*  text-decoration: none;*/
/*  color: white;*/
/*}*/
/deep/ #appBar .v-toolbar__extension {
  justify-content: center;
  background-color: #1e1e1e;
  border-bottom: 1px solid #FFAE00 !important;
}

#appBar {
  border-bottom: 1px solid rgb(31, 121, 254);
}

::v-deep #appBar .v-toolbar__content {
  justify-content: center;
  display: block;
}

.v-tab {

  /*font-size: 0.905 rem;*/
  /*font-family: NanumGothic !important;*/
  font-weight: 600;
  /*color:#c3c3c3 !important;*/
}

.menuTab {
  border-bottom: 1px solid #FFAE00 !important;
}

.selectedMenu, .v-tab--active {
  /*background-color: #2196f3*/
  background-color: #FFAE00 !important;
  color: #000 !important;
  /*background: radial-gradient(#762006bf, transparent);*/
  font-weight: 0 !important;
}

p {
  margin-right: 5px
}

nav {
  height: calc(var(--vh, 1vh) * 100) !important;
}


.left-partner-menu .v-list-item__title {
  color: #989292;
}

.left-partner-menu .v-list-item {
  padding-left: 0px !important;
}

.left-partner-menu .v-list-item--active {
  padding-left: 0px !important;
}

.left-partner-menu .v-list-item__content {
  padding-left: 10px !important;
}

.v-application .left-partner-menu .primary--text {
  color: #636363 !important;
  caret-color: #636363 !important;
}

.css-1v9s7at {
  background-color: rgb(30, 35, 41);
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.css-x3h831 {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}

.css-1v9s7at .inner {
  color: rgb(127, 132, 140);
}

.css-1v9s7at .MuiContainer-root .inner > img {
  height: 55px;
}

.css-1v9s7at .copyright {
  color: rgb(127, 132, 140);
}

</style>

