<template>
  <router-view/>
</template>

<script>
import '@/styles/index.css'
import '@/styles/added.css'

export default {
  name: 'App',
  components: {},
};
</script>


<style>

#app {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px !important;

  margin: auto;
  /*max-width: 1920px !important;*/
  /*background: #000000 !important;*/

}
.blackBg {
  background: transparent !important;
}

/*.v-toolbar__content, .v-toolbar__extension {*/
/*    max-width: 1920px !important;*/
/*}*/

html {
  font-size: 14px !important;
}

.betSelected {
  background: #ffae00 !important;
  color: #000 !important;
}

.v-application ul, .v-application ol {
  padding-left: 0px;
}

</style>
