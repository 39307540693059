<template>
  <div id="SideLeagues" class="active">
    <div style="padding: 10px;">최신 인기 게임</div>
    <ul class="pop-game">
      <div class="game">
        <div>11:40</div>
        <div class="sport-icon icon-game-Basketball"></div>
        <div>
          <div>LA 클리퍼스</div>
          <div>오클라호마시티 썬더</div>
        </div>
      </div>
      <div class="game">
        <div>13:00</div>
        <div class="sport-icon icon-game-Baseball"></div>
        <div>
          <div>KT</div>
          <div>LG</div>
        </div>
      </div>
      <div class="game">
        <div>20:00</div>
        <div class="sport-icon icon-game-Football"></div>
        <div>
          <div>대한민국</div>
          <div>콜롬비아</div>
        </div>
      </div>
      <div class="game">
        <div>19:30</div>
        <div class="sport-icon icon-game-Football"></div>
        <div>
          <div>일본</div>
          <div>우루과이</div>
        </div>
      </div>
    </ul>
    <div class="yellowcolor" style="padding: 10px;">최신 인기 리그</div>
    <ul class="popular">
      <li class="popular_li"><a class="title pop-title">
        <img
            src="/downloads?type=league&amp;filename=88761dbf2afaba573109b475a1ae6df56c7f830f6c51e278290c6786cd83ec29.jpeg"
            class="round">
        미국 - NBA
      </a></li>
      <li class="popular_li"><a class="title pop-title"><img
          src="/downloads?type=league&amp;filename=71d4119f0ac0418d215fc3a7dffd80342ddb985401d133eaa87397e8b2244c5c.jpeg"
          class="round">
        대한민국 - KBO
      </a></li>
      <li class="popular_li"><a class="title pop-title"><img
          src="https://flagcdn.com/w40/un.jpg"
          class="round">
        국제 - INTERF
      </a></li>
      <li class="popular_li"><a class="title pop-title"><img
          src="/files/board/gr3bhF4ARNOe.jpg?name=KBL.jpg"
          class="round">
        대한민국 - KBL
      </a></li>
      <li class="popular_li"><a class="title pop-title"><img
          src="/files/board/ChhU4htlBVhp.png?name=20211123_152321.png"
          class="round">
        대한민국 - V-리그
      </a></li>
    </ul> <!---->
    <div style="padding: 10px;">종목별</div>
    <li class="collapsed level1_1 foldmenu icon-game-Volleyball fold" data-toggle="collapse">
      <a class="title" @click="showDepth('volleyball')">배구<span class="sub yellowcolor">1</span></a>
      <ul class="level2 sub-menu collapse out" ref="volleyball" style="display:none;">
        <li class="foldmenu"><a @click="showDepth('volleyball-korea')">
          <img src="https://flagcdn.com/w40/kr.jpg" class="round">
          대한민국
          <span class="sub">1</span></a>
          <ul class="level-3" style="display:none" ref="volleyball-korea">
            <li><a><img src="/files/board/ChhU4htlBVhp.png?name=20211123_152321.png" class="round">
              대한민국 - V-리그
              <span class="sub">1</span></a></li>
          </ul>
        </li>
      </ul>
    </li>
  </div>
</template>
<script>

export default {
  data: () => ({}),
  methods: {
    showDepth: function (country) {
      if(this.$refs[country].style.display === 'none'){
        this.$refs[country].style.display = 'block'
      }
      else{
        this.$refs[country].style.display = 'none'
      }
    }
  }
}

</script>