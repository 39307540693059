'use strict'

import Vue from 'vue'
import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || "";
axios.defaults.baseURL =
    process.env.VUE_APP_BASE_URL || process.env.apiUrl || "";

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
// axios.defaults.withCredentials = true
// axios.defaults.baseURL = "http://localhost:8088";
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        if (localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null) {
            config['headers'] = {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        } else {
            config['headers'] = {
                Authorization: ''
            }
        }
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    },
)

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error)
    },
)

Plugin.install = function (Vue, options) {
    Vue.axios = _axios
    window.axios = _axios
    Object.defineProperties(Vue.prototype, {
        axios: {
            get () {
                return _axios
            },
        },
        $axios: {
            get () {
                return _axios
            },
        },
    })
}

Vue.use(Plugin)

export default Plugin
