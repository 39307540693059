<template>
  <v-simple-table class="mb-4">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center" style="width:15%">
          경기시간
        </th>
        <th class="text-center">
          리그
        </th>
        <th class="text-center" style="width:50%" colspan="3">
          팀명
        </th>
        <th class="text-center" colspan="2">
          상태
        </th>
      </tr>
      </thead>
      <tbody
          v-for="(betItem,i) in myBetList.items"
          :key="i"
      >
      <!--당첨-->
      <tr

          class="game-info">

        <td rowspan="2"
            class="text-center">
          <div
              class="ovm-ClassificationBarButton_Icon cil-ClassificationIconLarge cil-ClassificationIconLarge-1 "></div>
          {{ betItem.matchedAt }}
        </td>
        <td
            rowspan="2"
            class="text-center">
          {{ betItem.league }}
        </td>

        <td
            class="text-center "
            :class="betItem.whichBet === 'DOG_1' ? 'betSelected font-weight-bold': ''">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.teamHome}}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsHome }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_2' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team3}}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsDraw }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_3' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.teamAway}}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsAway }}
            </div>
          </div>
        </td>
        <td
            rowspan="2"
            colspan="2"
            class="text-center">
                <span>
                  {{ betItem.statusValue }}
                </span>
        </td>
      </tr>
      <tr>
        <td
            class="text-center "
            :class="betItem.whichBet === 'DOG_4' ? 'betSelected font-weight-bold': ''">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team4}}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsExtra1 }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_5' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team5}}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsExtra2 }}
            </div>
          </div>
        </td>
        <td class="text-center"
            :class="betItem.whichBet === 'DOG_6' ? 'betSelected font-weight-bold': ''"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center text-left">
              {{ betItem.team6}}
            </div>
            <div class="d-flex align-center">
              {{ betItem.oddsExtra3 }}
            </div>
          </div>
        </td>
      </tr>

      <tr class="win">
        <td class="text-center">

          <v-checkbox
              v-show="!isWrite"
              dense
              class="justify-center"
              hide-details
              :value="myBetList.selected"
              @click.stop.prevent='updateCheck'
          >
          </v-checkbox>
        </td>
        <td colspan="6" class="text-center">
          <div class="d-flex align-center justify-space-between">
            <div>
              베팅시간 : {{ myBetList.betAt }}
            </div>
            <div>
              베팅금액 : {{ myBetList.betAmount | comma }}원
            </div>
            <div>
              배당 : {{ myBetList.odds }}
            </div>
            <div v-if="myBetList.status === 'BET'">
              예상 당첨금 : {{ myBetList.estimateAmount | comma }}원
            </div>
            <div v-else-if="myBetList.status==='WON'">
              당첨금 : {{ myBetList.winAmount | comma }}원
            </div>
            <div v-else>
              당첨금 : 0원
            </div>
            <div style="min-width: 250px;"
                 class="d-flex align-center justify-space-around">
              <div class="d-flex">
<!--                <v-btn
                    :class="isWrite ? 'hidden': ''"
                    color="write"
                    @click="attachBoard([myBetList.betId])">첨부
                </v-btn>-->
                <v-btn
                    class="hidden"
                    color="#455A64"
                    @click="cancelBet(myBetList.betId)">취소
                </v-btn>
              </div>
              <div class="">
                <v-chip
                    class="ma-2"
                    label
                    :color="myBetList.status === 'WON' ? 'success' : (myBetList.status === 'NOT_WON' ? 'error' : 'primary' )"
                    text-color="white"
                    style="pointer-events: none"
                >
                  {{ myBetList.statusValue }}
                </v-chip>
              </div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "SportsPc",
  props: {
    myBetList: {
      type: Object
    },
    myBetListIndex: {
      type: Number
    },
    isWrite: {
      type: Boolean
    }
  },
  methods: {
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    attachBoard: function (id) {
      this.$emit('attachBoard', id)
      console.log('id', id)
    },
    updateCheck: function () {
      this.$emit('updateCheck', this.myBetListIndex, this.selectAll)
    },

  }

}
</script>

<style scoped>

</style>