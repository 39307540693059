<template>
  <div>
    <div class="head_info" data-v-00c483cc="">
      <div class="hinfo_wrap" data-v-00c483cc="">
        <div class="topbanner" data-v-00c483cc="">
          <div class="topbannerin" data-v-00c483cc="">
            <div class="speakerIcon" data-v-00c483cc=""><img src="/img/icon_mega.ce31ec0a.svg" data-v-00c483cc=""></div>
            <span class="" data-v-00c483cc="">※ 입금전 계좌문의 후 입금해 주시기 바랍니다.</span></div>
        </div>
        <div class="minfo" data-v-00c483cc="" v-if="isLogin">
          <ul class="coninpoint" data-v-00c483cc="">
            <li data-v-00c483cc=""><span data-v-00c483cc="">{{ nickname }}</span></li>
            <li data-v-00c483cc="" style="cursor: pointer;" @click="$move('/deposit')"><img src="/img/hcoin.57b614bf.svg" data-v-00c483cc="">
              <span
                data-v-00c483cc="" >{{balance.money | comma}}</span></li>
            <li data-v-00c483cc="" style="cursor: pointer;" @click="$move('/point')"><img src="/img/hpoint.6b4b7e90.svg" data-v-00c483cc=""><span
                data-v-00c483cc="">{{ parseInt(balance.mileage) | comma }}</span></li>
            <li class="mymsgs mr-5" data-v-00c483cc="" @click="$move('/message')"><em data-v-00c483cc=""></em><span data-v-00c483cc="">{{unreadMessage}}</span></li>
<!--            <li data-v-00c483cc="">
              <button class="exBtn" data-v-00c483cc="">통합머니 전환</button>
            </li>-->
          </ul>
          <!--          <div class="loginWrap" data-v-00c483cc="">
                      <div class="logindropdown" data-v-00c483cc="">
                        <button class="logindropbtn langbtn" data-v-00c483cc=""><span class="langsp lang" data-v-00c483cc=""><img
                            src="/img/korea.27bab7b1.svg" data-v-00c483cc=""><span data-v-00c483cc="">KR</span></span>&lt;!&ndash;&ndash;&gt;
                          &lt;!&ndash;&ndash;&gt;</button>&lt;!&ndash;&ndash;&gt;</div>
                    </div>-->
        </div>
      </div>
    </div>

    <ul
        v-if="!$route.path.includes('partner/')"
        class="header" data-v-00c483cc="">
      <li class="logo" @click="$move('/')" data-v-00c483cc=""><a data-v-00c483cc=""><img src="@/assets/img/logo.png"
                                                                                         data-v-00c483cc=""></a></li>
      <li data-v-00c483cc="">
        <div class="naviwrap" data-v-00c483cc="">
          <ul class="navi" data-v-00c483cc="">
            <v-menu
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
            <li class="dropdown" data-v-00c483cc="" v-bind="attrs"  v-on="on" @click="$move('/casino/live')">
              <a class="dropdown-toggle" data-v-00c483cc="">라이브 카지노</a><!---->
            </li>
              </template>

            </v-menu>
<!--            <v-menu
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <li class="dropdown" data-v-00c483cc="" v-bind="attrs"  v-on="on" @click="$move('/casino/hotel')">
                  <a class="dropdown-toggle" data-v-00c483cc="">호텔 카지노</a>&lt;!&ndash;&ndash;&gt;
                </li>
              </template>



            </v-menu>-->
            <v-menu
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
              <li class="dropdown" data-v-00c483cc="" v-bind="attrs"  v-on="on" @click="$move('/casino/slot')"><a class="dropdown-toggle" data-v-00c483cc="">슬롯</a><!----></li>
              </template>
            </v-menu>
            <!----><!---->
            <li class="dropdown" @click="$move('/notice/NOTICE')" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="">공지사항</a></li>
            <li class="dropdown" @click="$move('/notice/EVENT')" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="">이벤트</a></li>
            <li class="dropdown" @click="$move('/customer')" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="">고객센터</a></li>

            <v-menu
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <li class="dropdown" data-v-00c483cc="" v-bind="attrs"  v-on="on">
                  <a class="dropdown-toggle" data-v-00c483cc="" @click="$move('/deposit')">입/출금</a>
                </li>
              </template>
              <v-list>
                <v-list-item @click="$move('/deposit')">
                  <v-list-item-title class="navi_menu_item">입금</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$move('/withdrawal')">
                  <v-list-item-title class="navi_menu_item">출금</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!--            <li class="dropdown" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="" @click="$move('/deposit')">입/출금</a>&lt;!&ndash;&ndash;&gt;</li>-->
            <li class="dropdown" data-v-00c483cc="" @click="$move('/mybet')"><a class="dropdown-toggle" data-v-00c483cc="">마이페이지</a></li>
            <li
                v-if="(role ==='ROLE_AGENCY' && rateFlag !== 'false')"
                class="dropdown" data-v-00c483cc="" @click="$move('/partner/member')"><a class="dropdown-toggle" data-v-00c483cc="">파트너페이지</a></li>
          </ul>
          <div class="d-flex justify-space-between">
            <div class="mr-1 router-link-active router-link-exact-active loginbtn" v-if="!isLogin"
                 data-v-6e12d786="" data-v-00c483cc="" @click="openJoinDialog">회원가입
            </div>
            <div class="mr-1 router-link-active router-link-exact-active loginbtn" v-if="!isLogin"
                 data-v-6e12d786="" data-v-00c483cc="" @click="openLoginDialog">로그인
            </div>
            <div class="router-link-active router-link-exact-active loginbtn" v-if="isLogin"
                 data-v-6e12d786="" data-v-00c483cc="" @click="logout">로그아웃
            </div>
          </div>
        </div>
      </li>
    </ul>

<!--    <ul-->
<!--        v-else-->
<!--        class="header" data-v-00c483cc="">-->
<!--      <li class="logo" @click="$move('/')" data-v-00c483cc=""><a data-v-00c483cc=""><img src="/img/playcasinologo.7c8beb64.png"-->
<!--                                                                                         data-v-00c483cc=""></a></li>-->
<!--      <li data-v-00c483cc="">-->
<!--        <div class="naviwrap" data-v-00c483cc="">-->
<!--          <ul class="navi" data-v-00c483cc="">-->
<!--            <li class="dropdown" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="">입출금내역</a>&lt;!&ndash;&ndash;&gt;</li>-->
<!--            <li class="dropdown" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="">배팅내역</a>&lt;!&ndash;&ndash;&gt;</li>-->
<!--            <li class="dropdown" @click="$move('/notice/NOTICE')" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="">파트너정산내역</a></li>-->
<!--            <li class="dropdown" @click="$move('/customer')" data-v-00c483cc=""><a class="dropdown-toggle" data-v-00c483cc="">유저정산내역</a></li>-->
<!--            <li class="dropdown" data-v-00c483cc="" @click="$move('/mybet')"><a class="dropdown-toggle" data-v-00c483cc="">하부파트너목록</a></li>-->
<!--            <li class="dropdown" data-v-00c483cc="" @click="$move('/partner/dashboard')"><a class="dropdown-toggle" data-v-00c483cc="">소속회원목록</a></li>-->
<!--            <li class="dropdown" data-v-00c483cc="" @click="$move('/partner/dashboard')"><a class="dropdown-toggle" data-v-00c483cc="">회원머니지급</a></li>-->
<!--          </ul>-->
<!--            <div class="router-link-active router-link-exact-active loginbtn" v-if="isLogin"-->
<!--                 data-v-6e12d786="" data-v-00c483cc="" @click="logout">로그아웃-->
<!--            </div>-->
<!--        </div>-->
<!--      </li>-->
<!--    </ul>-->
    <template
        v-else
    >
      <div class="secondary_header css-6iffui agencyBarBg"
           :class="openMenuListFlag? 'open': 'close'"
      >
        <div class="dimmed_layer"
             :class="openMenuListFlag? 'open': 'close'"
             @mouseover="openMenuList" @mouseleave="closeMenuList"
        ></div>
        <div class="MuiContainer-root MuiContainer-maxWidthLg css-10p9vcd">
          <div class="MuiGrid-root MuiGrid-container css-z68i50">
            <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 main_menu_wrap css-15j76c0">
              <div class="logo css-s2lwup" @click="$move('/')"><a><img src="@/assets/img/logo.png" alt="LOGO"></a></div>
              <ul class="main_menu" @mouseover="openMenuList" @mouseleave="closeMenuList">
<!--                <li><a class="nav-link">홈</a>-->
<!--                  <ul class="sub_menu">-->
<!--                    <li class="sub_menu_link" @click="$move('/partner/dashboard')">대시보드</li>-->
<!--                  </ul>-->
<!--                </li>-->
                <li><a class="nav-link">정산</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/stats')">파트너정산</li>
                  </ul>
                </li>
                <li><a class="nav-link">회원관리</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/member')">회원리스트</li>
                    <li class="sub_menu_link" @click="$move('/partner/logOn')">현재접속자</li>
                  </ul>
                </li>


              </ul>

              <ul class="main_menu" @mouseover="openMenuList" @mouseleave="closeMenuList">
                <li><a class="nav-link">충/환전</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/exchange')">충/환전 내역</li>
                  </ul>
                </li>
                <li><a class="nav-link">베팅</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/betHistory')">베팅 내역</li>
                  </ul>
                </li>
<!--                <li><a class="nav-link">통계</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link" @click="$move('/partner/gameSummary')">게임 통계</li>
                    <li class="sub_menu_link" onclick="alert('점검중입니다. 파트너정산을 이용해주세요')">게임 통계</li>
                  </ul>
                </li>-->
                <!--
                <li><a class="nav-link">정산</a>
                  <ul class="sub_menu">
                    <li class="sub_menu_link">정산 내역</li>
                    <li class="sub_menu_link">정산 요청</li>
                  </ul>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: "userPc",
  data: () => ({
    openMenuListFlag: false,
    casinoList:[
      {
        productId: 1,
        vendor: "에볼루션",
        "type": "live"
      },
      {
        productId: 8,
        vendor: "드림게임",
        "type": "live"
      },
      {
        productId: 12,
        vendor: "WM 라이브",
        "type": "live"
      },
      {
        productId: 28,
        vendor: "TG 스피드",
        "type": "live"
      },
      {
        productId: 29,
        vendor: "아시아 게이밍",
        "type": "live"
      },
      {
        productId: 33,
        vendor: "프라그마틱플레이 라이브",
        "type": "live"
      },
      {
        productId: 46,
        vendor: "벳게임TV",
        "type": "live"
      },
      {
        productId: 47,
        vendor: "스카이윈드",
        "type": "live"
      },
      {
        productId: 51,
        vendor: "타이샨",
        "type": "live"
      },

      {
        productId: 54,
        vendor: "마이크로게이밍 플러스",
        "type": "live"
      },
      {
        productId: 60,
        vendor: "플레이테크",
        "type": "live"
      },
    ],

    hotelCasinoList:[
      {
        productId: 28,
        vendor: "TG 스피드",
        "type": "live"
      },
      {
        productId: 53,
        vendor: "두윈",
        "type": "live"
      }
    ],

    slotList:[

      {
        productId: 7,
        vendor: "프라그마틱플레이",
        "type": "slot"
      },
      {
        productId: 26,
        vendor: "플레이앤고",
        "type": "slot"
      },
      {
        productId: 3,
        vendor: "에보플레이",
        "type": "slot"
      },
      /*{
        productId: 4,
        vendor: "CQ9",
        "type": "slot"
      },*/
      {
        productId: 6,
        vendor: "위그드라실",
        "type": "slot"
      },

      {
        productId: 9,
        vendor: "부운고",
        "type": "slot"
      },
      {
        productId: 17,
        vendor: "와즈단",
        "type": "slot"
      },
      {
        productId: 20,
        vendor: "넷엔트",
        "type": "slot"
      },
      {
        productId: 22,
        vendor: "레드 타이거",
        "type": "slot"
      },
      {
        productId: 23,
        vendor: "플레이슨",
        "type": "slot"
      },
      {
        productId: 24,
        vendor: "플레이스타",
        "type": "slot"
      },
      /*{
        productId: 25,
        vendor: "하바네로",
        "type": "slot"
      },*/

      {
        productId: 27,
        vendor: "릴랙스게이밍",
        "type": "slot"
      },
      {
        productId: 34,
        vendor: "트리플프로핏게이밍",
        "type": "slot"
      },
      {
        productId: 35,
        vendor: "게임아트",
        "type": "slot"
      },
      {
        productId: 37,
        vendor: "PG 소프트",
        "type": "slot"
      },
      {
        productId: 40,
        vendor: "블루프린트게이밍",
        "type": "slot"
      },
      {
        productId: 41,
        vendor: "썬더킥",
        "type": "slot"
      },
      {
        productId: 42,
        vendor: "노리밋시티",
        "type": "slot"
      },
      {
        productId: 43,
        vendor: "모빌로츠",
        "type": "slot"
      },
      {
        productId: 44,
        vendor: "플레이펄스",
        "type": "slot"
      },
      {
        productId: 45,
        vendor: "드라군소프트",
        "type": "slot"
      },
      {
        productId: 49,
        vendor: "1X2 게이밍",
        "type": "slot"
      },
      {
        productId: 50,
        vendor: "엘크 스튜디오스",
        "type": "slot"
      },
      {
        productId: 52,
        vendor: "릴킹덤",
        "type": "slot"
      },
      {
        productId: 55,
        vendor: "마이크로게이밍 플러스",
        "type": "slot"
      },
      {
        productId: 56,
        vendor: "빅타임 게이밍",
        "type": "slot"
      },
      {
        productId: 58,
        vendor: "아바타럭스",
        "type": "slot"
      },
      {
        productId: 59,
        vendor: "핵소",
        "type": "slot"
      },
      {
        productId: 60,
        vendor: "플레이테크",
        "type": "live"
      },
      {
        productId: 61,
        vendor: "플레이테크",
        "type": "slot"
      }
    ],



  }),
  props: {
    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    },
    isLogin: {
      type: Boolean
    },
    role: {
      type: String
    },
    rateFlag: {
      type: String
    },
    hasChildren: {
      type: String
    }

  },
  methods: {
    openJoinDialog: function () {
      this.$emit('openJoinDialog')
    },
    logout: function () {
      this.$emit('logout');
    }
    ,
    goLink: function (target) {
      this.$emit('goLink', target);
    }
    ,
    openRecommenderDialog: function () {
      this.$emit('openRecommenderDialog')
    }
    ,
    openLoginDialog: function () {
      this.$emit('openLoginDialog')
    }
    ,
    openMenuList: function () {
      this.openMenuListFlag = true
    }
    ,
    closeMenuList: function () {
      this.openMenuListFlag = false
    }
    ,

    launchGame: function (item) {
      this.$emit('launchGame', item)
    },
    getGameList: function (item) {
      this.$emit('getGameList',item)
    }

  },

  computed: {
    // isLogin() {
    //   console.log('islogin',localStorage)
    //   return !!localStorage.accessToken;
    // }
  }
  ,
  watch: {}
}
</script>

<style scoped>

.navitop[data-v-00c483cc] {
  font-size: 14px;
  border-bottom: 1px solid #bebebe;
  padding-bottom: 14px;
  color: #fff;
  padding:10px !important;
}

</style>
