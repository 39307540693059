<template>
  <div>
    <div
        v-for="(item, i) in myBetList"
        :key="i"
    >
      <div
          v-if="menuType === ''"
          class="text-left"
      >
        [{{ item.menuCodeText }}]
      </div>
      <sports
          v-if="item.menuCode === 'SPORTS_GAME' ||
          item.menuCode === 'MATCH' ||
          item.menuCode === 'CROSS' ||
          item.menuCode === 'HANDICAP' ||
          item.menuCode === 'LIVE' ||
          item.menuCode === 'SPECIAL' ||
          item.menuCode === 'VSOCCER' ||
          item.menuCode === 'VBASKETBALL'"
          :myBetList="item"
          :myBetListIndex="i"
          :isWrite="true"
      >
      </sports>
      <power-ball
          v-else-if="menuCode === 'POWERBALL' || menuCode === 'POWERBALL3M' || menuCode ==='SPEEDKENO'"
          :myBetList="item"
          :myBetListIndex="i"
          :isWrite="true"
      >
      </power-ball>
      <v-dog-racing
          v-else-if="item.menuCode === 'VDOGRACING'"
          :myBetList="item"
          :myBetListIndex="i"
          :isWrite="true"
      >
      </v-dog-racing>


    </div>
  </div>


</template>

<script>


// import axios from "axios";
import Sports from "@/components/Mybet/GameType/Sports/Pc";
import PowerBall from "@/components/Mybet/GameType/PowerBall/Pc";
import VDogRacing from "@/components/Mybet/GameType/DogRacing/Pc";
export default {
  name: "BoardPc",
  data: () => ({

  }),
  components: {
    Sports,
    PowerBall,
    VDogRacing
  },

  props: {
    writeData: {
      type: Object,
    },
    myBetList: {
      type: Array
    },
    menuType : {
      type : String
    }
  },
  methods: {}
}


</script>