<template>
  <v-dialog
      :value="dialog"
      @input="alertAction"
      light
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :persistent="option.persistent ? option.persistent : false"
  >
    <v-card
      class=""
    >
      <v-card-title  class="justify-center">
        <div class="text-center"
             :class="$vuetify.breakpoint.lgAndUp? 'pa-16':''"
             :style="$vuetify.breakpoint.lgAndUp? {'font-size':'70px'}:{'font-size':'28px'}">
          <span>
          사이트
          </span>
          <span>
           점검중
          </span>
        </div>

      </v-card-title>
      <v-card-text class="pa-5 text-center">
        <!--        <slot>{{ text }}</slot>-->
        <v-icon
                color="red"
                :style="{'font-size':'150px'}"
        >mdi-alert</v-icon>
      </v-card-text>
      <v-card-text class="pa-5 text-center">
<!--        <slot>{{ text }}</slot>-->
        <div v-html="text" style="font-size: 20px"></div>

      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TextAlert",
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    text: {
      type: String,
    },
    option: {
      type:Object,
      default:Object
    }

  },
  methods: {
    alertAction: function () {
      this.$emit('alertAction');
    },
  }
}
</script>

<style scoped>

</style>