<template>
  <div>
    <v-card
        elevation="24"
    >
        <v-simple-table>
          <tbody
              v-for="(betItem,j) in myBetList.items"
              :key="j"
          >
          <tr class="game-info">
            <td colspan="4" class="text-left">
              <div class="d-flex justify-space-between">
                <div class="d-flex font-11">
                  <div class="pr-2">
                    [{{ myBetList.menuCodeText }}]
                  </div>
                  <div>
                    {{ betItem.league }}
                  </div>

                </div>
                <div>
                  {{ betItem.matchedAt }}
                </div>
              </div>

            </td>

          </tr>

          <tr class="game-info">
            <td class="text-center "
                :class="betItem.whichBet === 'HOME' ? 'betSelected font-weight-bold': ''"
                style="width:39%">
              <div class="d-flex justify-space-between">

                <div class="d-flex align-center matchTeamNameMobile" :style="teamName">
                  {{
                    betItem.special === '' || betItem.special === null ? betItem.teamHome : betItem.teamHome + ' [' + betItem.special + ']'
                  }}
                </div>
                <v-icon
                    v-if="betItem.gameCode ==='OVER_UNDER'"
                    medium
                    color="red"
                    style="font-size:15px"
                >mdi-arrow-up</v-icon>
                <div class="d-flex align-center font-11">
                  {{ betItem.oddsHome }}
                </div>

              </div>
            </td>
            <td class="text-center"
                :class="betItem.whichBet === 'DRAW' ? 'betSelected font-weight-bold': ''"
            >
              <div class="d-flex align-center font-11">
                <span v-if="betItem.oddsDraw === 0 && betItem.gameCode.indexOf('MATCH') !== -1">VS</span>
                <span v-else-if="betItem.oddsDraw !== 0 && betItem.gameCode.indexOf('MATCH') !== -1">{{betItem.oddsDraw}}</span>
                <span v-else>{{betItem.handicap}}</span>
              </div>
            </td>
            <td class="text-center"
                :class="betItem.whichBet === 'AWAY' ? 'betSelected font-weight-bold': ''"
                style="width:39%"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center font-11">
                  {{ betItem.oddsAway }}
                </div>
                <v-icon
                    v-if="betItem.gameCode ==='OVER_UNDER'"
                    medium
                    color="indigo"
                    style="font-size:15px"
                >mdi-arrow-down</v-icon>
                <div class="d-flex align-center matchTeamNameMobile" :style="teamName">
                  {{
                    betItem.special === '' || betItem.special === null ? betItem.teamAway : '[' + betItem.special + '] ' + betItem.teamAway
                  }}
                </div>
              </div>
            </td>
            <td class="text-center font-11" style="width:22%">
              <div v-if="betItem.scoreHome !== null && betItem.scoreHome !== null">
                {{ betItem.scoreHome }} : {{ betItem.scoreAway }}
              </div>
              <div v-else>
                {{ betItem.statusValue }}
              </div>
              <div>
                <v-icon color="success" small v-if="betItem.status==='WIN'">mdi-circle-outline</v-icon>
                <v-icon color="error" small v-else-if="betItem.status==='LOSE'">mdi-close</v-icon>
                <v-icon color="white" small v-else-if="betItem.status==='DENY' || betItem.status.includes('CANCEL')">mdi-cancel</v-icon>
                <v-icon color="white" small v-else-if="betItem.status==='READY'">mdi-timer-sand</v-icon>
                <v-icon color="white" small v-else-if="betItem.status==='CALCULATING'">mdi-calculator</v-icon>
                <v-icon color="primary" small v-else-if="betItem.status==='INVALID'">mdi-triangle-outline</v-icon>
              </div>
            </td>
          </tr>
          </tbody>

          <tr class="win">
            <td colspan="5" class="text-center pa-3">
              <div class="d-flex justify-space-between">
                <div>
                  <div>베팅금액 : {{ myBetList.betAmount | comma }}원</div>
                </div>
                <div>
                  <div>배당 : {{ myBetList.status === 'WON' ? myBetList.winOdds : myBetList.odds }}</div>
                </div>

                <div v-if="myBetList.status==='BET'">
                  <div>예상 당첨금 : {{ myBetList.estimateAmount | comma }}원</div>
                </div>
                <div v-else-if="myBetList.status==='WON'">
                  <div>당첨금 : {{ myBetList.winAmount | comma }}</div>
                </div>
                <div v-else>
                  당첨금 : 0원
                </div>
              </div>
            </td>
          </tr>
          <tr class="win">
            <td colspan="5" class="text-right pl-3">
              <div class="d-flex justify-space-between align-center">
                <div class="d-flex align-center">
                  <div>
                    <v-checkbox
                        v-show="!isWrite"
                        dense
                        class="justify-center ma-0"
                        hide-details
                        :value="myBetList.selected"
                        @click.stop.prevent='updateCheck'
                    >
                    </v-checkbox>
                  </div>

                  <div class="text-center">
                    <v-icon class="font-15">mdi-timer-outline</v-icon>
                    {{ myBetList.betAt }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-around">
                  <div class="d-flex">
<!--                    <v-btn
                        :class="isWrite ? 'hidden': ''"
                        color="write"
                        @click="attachBoard([myBetList.betId])">첨부
                    </v-btn>-->
                    <v-btn
                        :class="
                            (
                                myBetList.menuCode !== 'SPORTS_GAME' &&
                                myBetList.menuCode !== 'MATCH' &&
                                myBetList.menuCode !== 'CROSS' &&
                                myBetList.menuCode !== 'LIVE' &&
                                myBetList.menuCode !== 'HANDICAP' &&
                                myBetList.menuCode !== 'SPECIAL'


                            )
                            || myBetList.status !== 'BET' ? 'hidden': ''"
                        color="#455A64"
                        @click="cancelBet(myBetList.betId)">취소
                    </v-btn>
                  </div>
                  <div class="">
                    <v-chip
                        class="ma-2"
                        label
                        :color="myBetList.status === 'WON' ? 'success' : (myBetList.status === 'NOT_WON' ? 'error' : 'primary' )"
                        text-color="white"
                        style="pointer-events: none"
                    >
                      {{ myBetList.statusValue }}
                    </v-chip>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </v-simple-table>

      <v-divider
          class="mt-4"
          dark
      ></v-divider>
    </v-card>

    </div>


</template>

<script>
export default {
  name: "SportsMobile",
  props: {
    myBetList: {
      type: Object
    },
    myBetListIndex: {
      type: Number
    },
    isWrite: {
      type: Boolean
    }
  },
  methods: {
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    attachBoard: function (id) {
      this.$emit('attachBoard', id)
      console.log('id', id)
    },
    updateCheck: function () {
      this.$emit('updateCheck', this.myBetListIndex, this.selectAll)
    },

  },

  computed: {

    teamName() {
      console.log(this.$vuetify.breakpoint)
      let width = ""
      if (this.$vuetify.breakpoint.xl) {
        width= '300px'
      } else if (this.$vuetify.breakpoint.lg) {
        width= '150px'
      } else if (this.$vuetify.breakpoint.md) {
        width= '100px'
      } else if (this.$vuetify.breakpoint.sm) {
        width= '80px'
      } else if (this.$vuetify.breakpoint.xs) {
        width= '80px'
      }
      return {
        width : width
      }
    },
  },
}
</script>

<style scoped>

.matchTeamNameMobile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap:normal;
}

</style>