import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";

Vue.use(Vuex);

// LOGIN 정보 보관
export default new Vuex.Store({
    // 컴포넌트 간 공유될 DATA
    state: {
        accessToken: null,
        refreshToken: null,
        memberId: null,
        username: null,
        nickname: null,
        grade: null,
        gradeValue: null,
        role: null,
        hasChildren: false,
        availableBalanceTransfer:null
    },
    getters: {},
    // state 값을 변경하는 로직 (setter 와 유사한 개념)
    mutations: {

        saveSignInInfo: function (state, payload) {
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
            state.memberId = payload.memberId;
            state.username = payload.username;
            state.nickname = payload.nickname;
            state.grade = payload.grade;
            state.gradeValue = payload.gradeValue;
            state.role = payload.role;
            state.hasChildren =payload.hasChildren;

            state.status = payload.status;
            state.statusValue = payload.statusValue;
            // 토큰을 로컬 스토리지에 저장
            localStorage.accessToken = payload.accessToken;
            localStorage.refreshToken = payload.refreshToken;
            localStorage.memberId = payload.memberId;
            localStorage.username = payload.username;
            localStorage.nickname = payload.nickname;
            localStorage.grade = payload.grade;
            localStorage.gradeValue = payload.gradeValue;
            localStorage.status = payload.status;
            localStorage.statusValue = payload.statusValue;
            localStorage.role = payload.role;
            localStorage.hasChildren = payload.hasChildren;
        },
        updateToken: function (state, payload) {
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;

            localStorage.accessToken = payload.accessToken;
            localStorage.refreshToken = payload.refreshToken;
        },
        updateMyInfo: function (state, payload) {
            
            state.agencyId = payload.agencyId;
            state.memberId = payload.memberId;
            state.username = payload.username;
            state.nickname = payload.nickname;
            state.grade = payload.grade;
            state.gradeValue = payload.gradeValue;
            state.status = payload.status;
            state.statusValue = payload.statusValue;
            state.hasChildren = payload.hasChildren;
            state.rateFlag = payload.rateFlag;
    
            
            localStorage.agencyId = payload.agencyId;
            localStorage.memberId = payload.memberId;
            localStorage.username = payload.username;
            localStorage.nickname = payload.nickname;
            localStorage.grade = payload.grade;
            localStorage.gradeValue = payload.gradeValue;
            localStorage.status = payload.status;
            localStorage.statusValue = payload.statusValue;
            localStorage.hasChildren = payload.hasChildren;
            localStorage.availableBalanceSend = payload.availableBalanceSend;
            localStorage.availableBalanceGet = payload.availableBalanceGet;
            localStorage.rateFlag = payload.rateFlag;

        },
        updateSiteInfo: function (state, payload) {
            state.constructionText = payload.constructionText;
            state.showMemberLevel = payload.showMemberLevel;
            state.siteName = payload.siteName;
            state.useCasino = payload.useCasino;
            state.useCharge = payload.useCharge;
            state.useEventAttendance = payload.useEventAttendance;
            state.useExchange = payload.useExchange;
            state.useInplay = payload.useInplay;
            state.useLive = payload.useLive;
            state.usePowerball = payload.usePowerball;
            state.usePowerball3m = payload.usePowerball3m;
            state.useSpeedkeno = payload.useSpeedkeno;
            state.useSports = payload.useSports;
            state.useTokenGame = payload.useTokenGame;
            state.useVbasketball = payload.useVbasketball;
            state.useVdogracing = payload.useVdogracing;
            state.useVsoccer = payload.useVsoccer;
            state.csSocialMediaAccount = payload.csSocialMediaAccount;

            localStorage.constructionText = payload.constructionText;
            localStorage.showMemberLevel = payload.showMemberLevel;
            localStorage.siteName = payload.siteName;
            localStorage.useCasino = payload.useCasino;
            localStorage.useCharge = payload.useCharge;
            localStorage.useEventAttendance = payload.useEventAttendance;
            localStorage.useExchange = payload.useExchange;
            localStorage.useInplay = payload.useInplay;
            localStorage.useLive = payload.useLive;
            localStorage.usePowerball = payload.usePowerball;
            localStorage.usePowerball3m = payload.usePowerball3m;
            localStorage.useSpeedkeno = payload.useSpeedkeno;
            localStorage.useSports = payload.useSports;
            localStorage.useTokenGame = payload.useTokenGame;
            localStorage.useVbasketball = payload.useVbasketball;
            localStorage.useVdogracing = payload.useVdogracing;
            localStorage.useVsoccer = payload.useVsoccer;
            localStorage.csSocialMediaAccount = payload.csSocialMediaAccount;

        },

        LOGOUT(state) {
            state.accessToken = null;
            state.refreshToken = null;
            
            state.agencyId = null;
            state.memberId = null;
            state.username = null;
            state.nickname = null;
            state.grade = null;
            state.gradeValue = null;
            state.status = null;
            state.statusValue = null;
            state.role = null;
            state.hasChildren = null;
            state.availableBalanceTransfer = null;

            state.constructionText = null;
            state.showMemberLevel = null;
            state.siteName = null;
            state.useCasino = null;
            state.useCharge = null;
            state.useEventAttendance = null;
            state.useExchange = null;
            state.useInplay = null;
            state.useLive = null;
            state.usePowerball = null;
            state.usePowerball3m = null;
            state.useSpeedkeno = null;
            state.useSports = null;
            state.useTokenGame = null;
            state.useVbasketball = null;
            state.useVdogracing = null;
            state.useVsoccer = null;
            state.csSocialMediaAccount = null;
            state.rateFlag = null;


            delete localStorage.accessToken;
            delete localStorage.refreshToken;
            delete localStorage.agencyId;
            delete localStorage.memberId;
            delete localStorage.username;
            delete localStorage.nickname;
            delete localStorage.grade;
            delete localStorage.gradeValue;
            delete localStorage.statusValue;
            delete localStorage.role;

            delete localStorage.constructionText;
            delete localStorage.showMemberLevel;
            delete localStorage.siteName;
            delete localStorage.useCasino;
            delete localStorage.useCharge;
            delete localStorage.useEventAttendance;
            delete localStorage.useExchange;
            delete localStorage.useInplay;
            delete localStorage.useLive;
            delete localStorage.usePowerball;
            delete localStorage.usePowerball3m;
            delete localStorage.useSpeedkeno;
            delete localStorage.useSports;
            delete localStorage.useTokenGame;
            delete localStorage.useVbasketball;
            delete localStorage.useVdogracing;
            delete localStorage.useVsoccer;
            delete localStorage.csSocialMediaAccount;
            delete localStorage.rateFlag;

        }
    },
    // 사용자의 입력에 따라 반응할 METHODS
    actions: {
        LOGIN: async function ({commit}, {username, password}) {
            return await axios
                .post("/app/auth/signIn", {
                    username,
                    password,
                    role: "ROLE_MEMBER",
                    domain: location.host
                })
                .then(response => {
                    console.log('login!!',response)
                    commit("saveSignInInfo", {
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken,
                        memberId: response.data.memberId,
                        username: response.data.username,
                        nickname: response.data.nickname,
                        grade: response.data.grade,
                        gradeValue: response.data.gradeValue,
                        role: response.data.role,
                        hasChildren: response.data.hasChildren,

                    });

                    // 성공적으로 로그인이 되었을 경우
                    if (response.status === 200) {
                        // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
                        axios.defaults.headers.common["Authorization"] = `Bearer ${
                            response.data.accessToken
                        }`;
                    } else {
                        console.log(JSON.stringify(response.data));
                        // alert("로그인 실패");
                    }
                });
            // .catch(ex => {
            //   console.warn("ERROR!!!!! : ", ex);
            //   alert("로그인 실패");
            // });
            // return axios.post(`${resourceHost}/login`, {email, password})
            //   .then(({data}) => {
            //     commit("LOGIN", data);
            //     // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
            //     axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
            //   });
        },
        REFRESHTOKEN: async function ({commit}) {
            // axios.defaults.headers.common["Authorization"] = undefined;
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async function (resolve, reject) {
                await axios
                    .post("/app/auth/refreshToken", {
                        memberId: localStorage.memberId,
                        refreshToken: localStorage.refreshToken
                    })
                    .then(response => {
                        console.log('refresh !!! STORE', response)
                        commit("updateToken", {
                            accessToken: response.data.accessToken,
                            refreshToken: response.data.refreshToken,

                        });

                        // 성공적으로 로그인이 되었을 경우
                        if (response.status === 200) {
                            // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
                            axios.defaults.headers.common["Authorization"] = `Bearer ${
                                response.data.accessToken
                            }`;
                        } else {

                            console.log("refreshToken 로그인 실패", response);
                            // alert("로그인 실패");
                        }
                        resolve(response)
                    }).catch(async ex => {
                        console.log("refresh !!! ex 로그인 실패")
                        console.log(ex)
                        reject(ex)
                    });
            });


        },
        UPDATEMYINFO: async function ({commit}) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async function (resolve, reject) {
                await axios
                    .get("/app/members/me", {})
                    .then(response => {
                        console.log('myinfo',response)
                        // 성공적으로 로그인이 되었을 경우
                        if (response.status === 200) {
                            // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
                            commit("updateMyInfo", {
                                agencyId: response.data.membersAgency.id,
                                memberId: response.data.memberId,
                                username: response.data.username,
                                nickname: response.data.nickname,
                                grade: response.data.grade,
                                gradeValue: response.data.gradeValue,
                                status: response.data.status,
                                statusValue: response.data.statusValue,
                                hasChildren: response.data.hasChildren,
                                availableBalanceSend: response.data.membersAgencyConfig.availableBalanceSend,
                                availableBalanceGet: response.data.membersAgencyConfig.availableBalanceGet,
                                rateFlag: (response.data.membersAgencyConfig.betCommissionCasinolive > 0 ||
                                    response.data.membersAgencyConfig.betCommissionCasinoslot > 0 ||
                                    response.data.membersAgencyConfig.losingCasino > 0),
                            });

                        } else {
                            // console.log(JSON.stringify(response.data));
                            // //정상 코드가 아닐시 로그아웃 처리
                            // this.$store
                            //     .dispatch("LOGOUT")
                            //     .then(() => {
                            //       this.$router.push({name: "login"})
                            //     })
                            //     .catch(ex => {
                            //       console.log("global logout")
                            //       console.log(ex)
                            //     });
                            // // alert("로그인 실패");

                        }
                        resolve(response)
                    }).catch(async ex => {
                        console.log("updateInfo 실패")
                        console.log(ex)
                        reject(ex)
                        // if (ex.response.status === 401) {
                        //   if (await this.$refreshToken()) {
                        //     console.log('aa')
                        //   } else {
                        //     console.log('bb')
                        //     this.$logout();
                        //   }
                        // } else if (ex.response.status === 403) {
                        //   this.$logout();
                        // }
                    });
            });
        },
        UPDATESITEINFO: async function ({commit}) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async function (resolve, reject) {
                await axios
                    .get("/app/common/appInfo", {})
                    .then(response => {
                        //response.data.underMaintenance = false
                        // 성공적으로 로그인이 되었을 경우
                        if (response.status === 200) {
                            // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
                            commit("updateSiteInfo", {
                                constructionText: response.data.constructionText,
                                showMemberLevel: response.data.showMemberLevel,
                                siteName: response.data.siteName,
                                useCasino: response.data.useCasino,
                                useCharge: response.data.useCharge,
                                useEventAttendance: response.data.useEventAttendance,
                                useExchange: response.data.useExchange,
                                useInplay: response.data.useInplay,
                                useLive: response.data.useLive,
                                usePowerball: response.data.usePowerball,
                                usePowerball3m: response.data.usePowerball3m,
                                useSpeedkeno: response.data.useSpeedkeno,
                                useSports: response.data.useSports,
                                useTokenGame: response.data.useTokenGame,
                                useVbasketball: response.data.useVbasketball,
                                useVdogracing: response.data.useVdogracing,
                                useVsoccer: response.data.useVsoccer,
                                csSocialMediaAccount: response.data.csSocialMediaAccount
                            });
                        } else {

                        }
                        resolve(response)
                    }).catch(async ex => {
                        console.log("updateInfo 실패")
                        console.log(ex)
                        reject(ex)
                    });
            });
        },
        LOGOUT: function ({commit}) {
            console.log('logoute!!!')
            axios.defaults.headers.common["Authorization"] = undefined;
            commit("LOGOUT");
        }
        // ,
        // ME: function(context) {
        //   axios.get("/api/v1/members/me")
        //     .then({ data } => {
        //     })
        //     .catch(ex => {
        //       console.warn("ERROR!!!!! : ", ex);
        //     });
        // }
    }
});

const enhanceAccessToken = () => {
    const {accessToken} = localStorage;
    if (!accessToken) {
        return;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};
enhanceAccessToken();
