<template>
  <v-dialog
      :value="dialog"
      @input="toggleDialog"
      max-width="1200px"
      content-class="casinoDialog overflow-y-auto"
      close-delay="2"
      class=""
  >

    <v-card
        tile
        class="fill-height"
        ref="matchList"
    >
      <div>
        <v-card-title class="justify-space-between"
                      ref="matchListTitle"
        >
          <span class="text-h5">{{ gameData.title }}</span>
          <v-btn
              icon
              class=""
              x-large
              color="#ffffff"
              @click="toggleDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <div class="position-relative">
          <div
              class="text-center fill-height w-100 position-absolute right-0 top-0"
          >
            <perfect-scrollbar
                ref="matchListScrollbar"
                v-resize="onResize"
                :style="scrollerStyle"
            >
              <v-row
                  no-gutters
              >
                <v-col

                    v-for="(item, i) in gameData.gameList"
                    :key="i"
                    class="pa-5"
                    @click="launchGame(item)"
                    :cols="setCols"
                >
                  <v-img
                      :src="item.code === 'speed' ? '/img/casino/tg_speed.png' : item.img_1"
                      class="white--text align-end"
                      transition="scale-transition"
                      contain
                  >
                  </v-img>
                  <div class="pt-1 text-center">
                    {{ item.name_kor }}
                  </div>
                </v-col>
              </v-row>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>


// import axios from "axios";
import Pc from "@/components/Board/Bet/Pc"
import Mobile from "@/components/Board/Bet/Mobile"

export default {
  data: () => ({
    matchListHeight: 0,

  }),
  components: {
    Pc,
    Mobile
  },

  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    gameData: {
      type: Object
    }

  },

  methods: {
    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:dialog', !this.dialog);
      // this.$emit('input');
    },
    launchGame: function (item) {
      console.log('launch game')
      this.$emit('launchGame', item)
    },
    onResize: function () {
      this.matchListHeight = this.$refs.matchList.$el.clientHeight - this.$refs.matchListTitle.clientHeight;
      console.log(this.matchListHeight)
    },

  },
  mounted() {
    this.onResize();
  },
  watch: {},
  computed: {
    scrollerStyle() {
      return {
        height: `${this.matchListHeight}px`,
      }
    },
    setCols() {
      let cols = 12
      if (this.$vuetify.breakpoint.xl) {
        cols = 2
      } else if (this.$vuetify.breakpoint.lg) {
        cols = 2
      } else if (this.$vuetify.breakpoint.md) {
        cols = 3
      } else if (this.$vuetify.breakpoint.sm) {
        cols = 4
      } else if (this.$vuetify.breakpoint.xs) {
        cols = 6
      }
      return cols
    },
  },
}
</script>

<style>
.casinoDialog {
  height: 70% !important;
}

</style>