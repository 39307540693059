<template>
  <div>

    <!--당첨-->
    <v-card
        elevation="24"
    >
      <v-simple-table
      >
        <tbody
            v-for="(betItem,j) in myBetList.items"
            :key="j">
        <tr class="game-info">
          <td colspan="4" class="text-left">
            <div class="d-flex justify-space-between">
              <div class="d-flex">

                <div>
                  {{ betItem.league }}
                </div>

              </div>
              <div>
                {{ betItem.matchedAt }}
              </div>
            </div>

          </td>

        </tr>
        <tr class="game-info">
          <td class="text-center "
              :class="betItem.whichBet === 'HOME' ? 'betSelected font-weight-bold': ''"
              style="width:39%">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center matchTeamNameMobile" :style="teamName">
                {{
                  betItem.special === '' || betItem.special === null ? betItem.teamHome : betItem.teamHome + ' [' + betItem.special + ']'
                }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsHome }}
              </div>
            </div>
          </td>
          <td class="text-center" v-if="betItem.gameCode === 'BALL_SIZE'"
              :class="betItem.whichBet === 'DRAW' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex align-center">
              {{ betItem.oddsDraw === 0.00 ? 'vs' : betItem.oddsDraw }}
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'AWAY' ? 'betSelected font-weight-bold': ''"
              style="width:39%"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                {{ betItem.oddsAway }}
              </div>
              <div class="d-flex align-center matchTeamNameMobile" :style="teamName">
                {{
                  betItem.special === '' || betItem.special === null ? betItem.teamAway : '[' + betItem.special + '] ' + betItem.teamAway
                }}
              </div>
            </div>
          </td>
          <td class="text-center" style="width:20%">
            <div>
              {{ betItem.statusValue }}
            </div>
            <div>
              <v-icon color="success" small v-if="betItem.status==='WIN'">mdi-circle-outline</v-icon>
              <v-icon color="error" small v-else-if="betItem.status==='LOSE'">mdi-close</v-icon>
              <v-icon color="white" small v-else-if="betItem.status==='DENY' || betItem.status.includes('CANCEL')">mdi-cancel</v-icon>
              <v-icon color="white" small v-else-if="betItem.status==='READY'">mdi-timer-sand</v-icon>
              <v-icon color="primary" small v-else-if="betItem.status==='INVALID'">mdi-triangle-outline</v-icon>
            </div>
          </td>
        </tr>

        </tbody>
        <tr class="win">
          <td colspan="5" class="text-center pa-3">
            <div class="d-flex justify-space-between">
              <div>
                <div>베팅금액 : {{ myBetList.betAmount | comma }}원</div>
              </div>
              <div>
                <div>배당 : {{ myBetList.odds }}</div>
              </div>
              <div v-if="myBetList.status==='BET'">
                <div>예상 당첨금 : {{ myBetList.estimateAmount | comma }}원</div>
              </div>
              <div v-else-if="myBetList.status==='WON'">
                <div>당첨금 : {{ myBetList.winAmount | comma }}</div>
              </div>
              <div v-else>
                당첨금 : 0원
              </div>
            </div>
          </td>
        </tr>
        <tr class="win">
          <td colspan="5" class="text-right pl-3">
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center">
                <div>
                  <v-checkbox
                      v-show="!isWrite"
                      dense
                      class="justify-center ma-0"
                      hide-details
                      :value="myBetList.selected"
                      @click.stop.prevent='updateCheck'
                  >
                  </v-checkbox>
                </div>
                <div class="text-center">
                  <v-icon class="font-15">mdi-timer-outline</v-icon>
                  {{ myBetList.betAt }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-around">
                <div class="d-flex">
<!--                  <v-btn
                      :class="isWrite ? 'hidden': ''"
                      color="write"
                      @click="attachBoard([myBetList.betId])">첨부
                  </v-btn>-->
                  <v-btn
                      class="hidden"
                      color="#455A64"
                      @click="cancelBet(myBetList.betId)">취소
                  </v-btn>
                </div>
                <div class="">
                  <v-chip
                      class="ma-2"
                      label
                      :color="myBetList.status === 'WON' ? 'success' : (myBetList.status === 'NOT_WON' ? 'error' : 'primary' )"
                      text-color="white"
                      style="pointer-events: none"
                  >
                    {{ myBetList.statusValue }}
                  </v-chip>
                </div>
              </div>
            </div>
          </td>
        </tr>

      </v-simple-table>
    </v-card>
    <v-divider
        class="mt-4"
        dark
    ></v-divider>
  </div>

</template>

<script>
export default {
  name: "SportsMobile",
  props: {
    myBetList: {
      type: Object
    },
    myBetListIndex: {
      type: Number
    },
    isWrite: {
      type: Boolean
    }
  },
  methods: {
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    attachBoard: function (id) {
      this.$emit('attachBoard', id)
      console.log('id', id)
    },
    updateCheck: function () {
      this.$emit('updateCheck', this.myBetListIndex, this.selectAll)
    },

  },

  computed: {

    teamName() {
      console.log(this.$vuetify.breakpoint)
      let width = ""
      if (this.$vuetify.breakpoint.xl) {
        width = '300px'
      } else if (this.$vuetify.breakpoint.lg) {
        width = '150px'
      } else if (this.$vuetify.breakpoint.md) {
        width = '100px'
      } else if (this.$vuetify.breakpoint.sm) {
        width = '80px'
      } else if (this.$vuetify.breakpoint.xs) {
        width = '80px'
      }
      return {
        width: width
      }
    },
  }

}


</script>

<style scoped>

</style>