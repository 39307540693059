<template>
  <v-simple-table class="mb-4">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center" style="width:15%">
          회차
        </th>
        <th class="text-center" style="width:15%">
          게임 종류
        </th>
        <th class="text-center" style="width:20%">
          승(HOME)
        </th>
        <th class="text-center" style="width:10%">
          무(기준점)
        </th>
        <th class="text-center" style="width:20%">
          패(AWAY)
        </th>
        <th class="text-center">
          상태
        </th>
      </tr>
      </thead>
      <tbody>
      <!--당첨-->
        <tr
            v-for="(betItem,i) in myBetList.items"
            :key="i"
            class="game-info">

          <td class="text-center">
            <div
                class="ovm-ClassificationBarButton_Icon cil-ClassificationIconLarge cil-ClassificationIconLarge-1 "></div>
            {{ betItem.gameDate }} {{ betItem.round }} 회차
          </td>
          <td class="text-center">
            {{ betItem.gameCodeText }}
          </td>
          <td
              class="text-center "
              :class="betItem.whichBet === 'HOME' ? 'betSelected font-weight-bold': ''">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center text-left">
                {{
                  betItem.gameCode === 'P_ODD_EVEN' ? '파워볼 홀' :
                      (betItem.gameCode === 'P_OVER_UNDER' ? '파워볼 오버' :
                          (betItem.gameCode === 'BALL_SIZE' ? '일반볼 소' :
                              (betItem.gameCode === 'P_SUM_ODD_EVEN' ? '일반볼 합 홀' :
                                  (betItem.gameCode === 'P_SUM_OVER_UNDER' ? '일반볼 합 오버' : ''))))
                }}
                {{
                  betItem.gameCode === 'ODD_EVEN' ? '홀' :
                      (betItem.gameCode === 'OVER_UNDER' ? '오버' :
                          (betItem.gameCode === 'ODD_OVER_UNDER' ? '홀+오버' :
                              (betItem.gameCode === 'EVEN_OVER_UNDER' ? '짝+오버' :'')))
                }}
              </div>
              <div class="d-flex align-center">
                {{ betItem.oddsHome }}
              </div>
            </div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'DRAW' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex align-center justify-center"
                 v-if="betItem.gameCode === 'BALL_SIZE'"
            >
              일반볼 중 {{betItem.oddsDraw}}
            </div>
            <div v-else>VS</div>
          </td>
          <td class="text-center"
              :class="betItem.whichBet === 'AWAY' ? 'betSelected font-weight-bold': ''"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                {{ betItem.oddsAway }}
              </div>
              <div class="d-flex align-center text-right">
                {{
                  betItem.gameCode === 'P_ODD_EVEN' ? '파워볼 짝' :
                      (betItem.gameCode === 'P_OVER_UNDER' ? '파워볼 언더' :
                          (betItem.gameCode === 'BALL_SIZE' ? '일반볼 대' :
                              (betItem.gameCode === 'P_SUM_ODD_EVEN' ? '일반볼 합 짝' :
                                  (betItem.gameCode === 'P_SUM_OVER_UNDER' ? '일반볼 합 언더' : ''))))
                }}
                {{
                  betItem.gameCode === 'ODD_EVEN' ? '짝' :
                      (betItem.gameCode === 'OVER_UNDER' ? '언더' :
                          (betItem.gameCode === 'ODD_OVER_UNDER' ? '홀+언더' :
                              (betItem.gameCode === 'EVEN_OVER_UNDER' ? '짝+언더' :'')))
                }}
              </div>
            </div>
          </td>

          <td class="text-center">
                <span>
                  {{ betItem.statusValue }}
                </span>
          </td>
        </tr>

        <tr class="win">
          <td class="text-center">
            <v-checkbox
                v-show="!isWrite"
                dense
                class="justify-center"
                hide-details
                :value="myBetList.selected"
                @click.stop.prevent='updateCheck'
            >
            </v-checkbox>
          </td>
          <td colspan="5" class="text-center">
            <div class="d-flex align-center justify-space-between">
              <div>
                베팅시간 : {{ myBetList.betAt }}
              </div>
              <div>
                베팅금액 : {{ myBetList.betAmount | comma }}원
              </div>
              <div>
                배당 : {{ myBetList.odds }}
              </div>
              <div v-if="myBetList.status === 'BET'">
                예상 당첨금 : {{ myBetList.estimateAmount | comma }}원
              </div>
              <div v-else-if="myBetList.status==='WON'">
                당첨금 : {{ myBetList.winAmount | comma }}원
              </div>
              <div v-else>
                당첨금 : 0원
              </div>
              <div style="min-width: 250px;"
                   class="d-flex align-center justify-space-around">
                <div class="d-flex">
<!--                  <v-btn
                      :class="isWrite ? 'hidden': ''"
                      color="write"
                      @click="attachBoard([myBetList.betId])">첨부
                  </v-btn>-->
<!--                  <v-btn
                      class="hidden"
                      color="#455A64"
                      @click="cancelBet(myBetList.betId)">취소
                  </v-btn>-->
                </div>
                <div class="">
                  <v-chip
                      class="ma-2"
                      label
                      :color="myBetList.status === 'WON' ? 'success' : (myBetList.status === 'NOT_WON' ? 'error' : 'primary' )"
                      text-color="white"
                      style="pointer-events: none"
                  >
                    {{ myBetList.statusValue }}
                  </v-chip>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "SportsPc",
  props: {
    myBetList: {
      type: Object
    },
    myBetListIndex: {
      type: Number
    },
    isWrite: {
      type: Boolean
    }
  },
  methods: {
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    attachBoard: function (id) {
      this.$emit('attachBoard', id)
      console.log('id',id)
    },
    updateCheck: function () {
      this.$emit('updateCheck', this.myBetListIndex, this.selectAll)
    },

  }

}
</script>

<style scoped>

</style>