import Vue from 'vue'

export const eventBus = new Vue({
    // 메소드로 만들어 형제 컴포넌트에서 선언한 userWasEdited를 메소드화
    methods:{
        updateTopBarData() {
            this.$emit('updateTopBarData');
        },
        getBalance(balance) {
            this.$emit('getBalance',balance);
        },
        getConfig(config) {
            this.$emit('getConfig',config);
        },
        getData() {
            this.$emit('getData');
        },
        getSiteInfo() {
            this.$emit('getSiteInfo');
        },

        getNowTime(nowTime) {
            this.$emit('getNowTime',nowTime);
        },
        initGameData(cart,gameList,gameDetailList,gameType) {
            this.$emit('initGameData',cart,gameList,gameDetailList,gameType);
        },
        refreshBySocket(item) {
            this.$emit('refreshBySocket',item)
        },
        refreshCartByBet(item) {
            this.$emit('refreshCartByBet',item)
        },
        showAlert(text,option,action) {
            this.$emit('showAlert',text,option,action);
        },
        initAlert() {
            this.$emit('initAlert');
        },
        clearInterval() {
            this.$emit('clearInterval');
        },
        startBalanceInterval() {
            this.$emit('startBalanceInterval');
        },

    }
})
