<template>
  <div>
    <table class="tbl_sports_detail">
      <colgroup>
        <col>
        <col style="width:8%">
        <col style="width:15%">
        <col style="width:30%">
        <col style="width:14%">
        <col>
        <col>
        <col style="width:10%">
      </colgroup>
      <thead>
      <tr>
        <th>종목</th>
        <th>시간</th>
        <th>리그</th>
        <th>경기</th>
        <th>베팅</th>
        <th>배당</th>
        <th>점수</th>
        <th>결과</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(betItem,i) in myBetList.items"
          :key="i"
      >
        <td v-if="$vuetify.breakpoint.lgAndUp">
          <i
              class="sports-icon sports-icon-big"
              :class="betItem.sports === '축구' ? 'icon-football' :
                               betItem.sports === '야구' ? 'icon-baseball' :
                               betItem.sports === '농구' ? 'icon-basketball' :
                               betItem.sports === '배구' ? 'icon-volleyball' :
                               betItem.sports === '아이스하키' ? 'icon-hockey' :
                               betItem.sports === '이스포츠' ? 'all' :
                               myBetList.menuCode === 'VSOCCER' ? 'icon-football' :
                               myBetList.menuCode === 'VBASKETBALL' ? 'icon-basketball' : ''"
          ></i></td>
        <td>{{ betItem.matchedAt | moment('MM/DD HH:mm') }}</td>
        <td class="text-ellipsis lo_icon">
          <img v-if="myBetList.menuCode !== 'VSOCCER' && myBetList.menuCode !== 'VBASKETBALL'"
              :src="betItem.leagueIcon"
              onerror="this.style='display:none"
          /> {{ betItem.league }}
        </td>
        <td class="text-ellipsis">
          <span>{{ betItem.teamHome }}<br>{{ betItem.teamAway }}</span>
        </td>
        <td>[{{betItem.gameCodeText}}]<br>
          <span class="yellow1" v-if="betItem.gameCode.indexOf('MATCH') !== -1">{{ betItem.whichBet === 'HOME' ? betItem.teamHome : betItem.whichBet === 'AWAY' ? betItem.teamAway : '무승부' }}</span>
          <span class="yellow1" v-if="betItem.gameCode.indexOf('HANDICAP') !== -1">{{ betItem.whichBet === 'HOME' ? betItem.teamHome + '(' + betItem.handicap * 1 +')' : betItem.whichBet === 'AWAY' ? betItem.teamAway  + '(' + betItem.handicap * -1  +')': '' }}</span>
          <span class="yellow1" v-if="betItem.gameCode.indexOf('OVER_UNDER') !== -1">{{ betItem.whichBet === 'HOME' ? '오버(' + betItem.handicap +')' : betItem.whichBet === 'AWAY' ? '언더(' + betItem.handicap +')' : '' }}</span>
        </td>
        <td>
          <span class="">{{ betItem.whichBet === 'HOME' ? betItem.oddsHome : betItem.whichBet === 'AWAY' ? betItem.oddsAway : betItem.oddsDraw }}</span>
        </td>
        <td>
          <span class="" v-if="betItem.scoreHome !== null && betItem.scoreHome !== null">{{ betItem.scoreHome }} : {{ betItem.scoreAway }}</span>
          <span class="" v-else>- : -</span>
        </td>
        <td><span class="status_fixture"
                  :style="betItem.statusValue === '대기' ? 'background:#666;' :
                        betItem.statusValue === '적중' ? 'background:#10085;' :
                        betItem.statusValue === '미적중' ? 'background:#cb0101;' : ''"
        >{{ betItem.statusValue }}</span></td>
      </tr>
      </tbody>
    </table>
    <div class="history-bottom">
      <div class="text-center" style="width:5%;flaot:left;margin:0px 0px 10px 10px;">
        <v-checkbox
            v-show="!isWrite"
            dense
            class="justify-center"
            hide-details
            :value="myBetList.selected"
            @click.stop.prevent='updateCheck'
        >
        </v-checkbox>
      </div>
      <div><span>베팅금액</span>{{ myBetList.betAmount | comma }}원</div>
      <div><span>총배당률</span>{{ myBetList.status === 'WON' ? myBetList.winOdds : myBetList.odds }}<span v-if="myBetList.bonus > 0">(*{{ myBetList.bonus }})</span></div>
      <div><span>예상적중금액</span>{{ myBetList.estimateAmount | comma }}원</div>
      <div>
        <span>실적중금액</span>
        <span v-if="myBetList.status === 'WON'">{{myBetList.winAmount | comma }}</span>
        <span v-else>-</span>
      </div>
      <div class="history-result">
        <v-chip
            class="ma-2"
            label
            :color="myBetList.status === 'WON' ? 'success' : (myBetList.status === 'NOT_WON' ? 'error' : 'primary' )"
            text-color="white"
            style="pointer-events: none"
        >
          {{ myBetList.statusValue }}
        </v-chip>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "SportsPc",
  props: {
    myBetList: {
      type: Object
    },
    myBetListIndex: {
      type: Number
    },
    isWrite: {
      type: Boolean
    }
  },
  methods: {
    cancelBet: function (id) {
      this.$emit('cancelBet', id)
    },
    attachBoard: function (id) {
      this.$emit('attachBoard', id)
      console.log('id', id)
    },
    updateCheck: function () {
      this.$emit('updateCheck', this.myBetListIndex, this.selectAll)
    },

  }

}
</script>

<style scoped>
@media (max-width: 767px){

  .tbl_sports_detail td {font-size:0.8em !important;}

}

.history-wrap span.yellow1 {
  color: #ef0!important;
}

</style>